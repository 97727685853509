import { Frame, Maximize } from 'lucide-react'
import React from 'react'

import { DisplayMode } from '@/components/Content/ContentCard'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import useDisplayMode from '@/components/User/useDisplayMode'

const DisplayModeToggle = () => {
    const { displayMode, setDisplayMode } = useDisplayMode()

    return (
        <ToggleGroup
            value={displayMode}
            type='single'
            className='border border-secondary rounded-md overflow-hidden'
            onValueChange={(value) => {
                if (value) setDisplayMode(value as DisplayMode)
            }}
        >
            <ToggleGroupItem className='flex items-center' value={DisplayMode.COVER} aria-label='cover'>
                <Frame className='w-4 h-4' />
            </ToggleGroupItem>
            <ToggleGroupItem value={DisplayMode.CONTAIN} aria-label='contain'>
                <Maximize className='w-4 h-4' />
            </ToggleGroupItem>
        </ToggleGroup>
    )
}
export default DisplayModeToggle
