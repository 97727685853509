import { clsx } from 'clsx'
import { CircleUserIcon, Infinity } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { Button } from '@/components/ui/button'
import { ModeToggle } from '@/components/ui/mode-toggle'
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'
import { cn } from '@/lib/utils'
import { navItemsLegal, socialLinks } from '@/nav-config'
import { useCredits } from '@/provider/credit-provider'
import useParams from '@/provider/query-provider'

const Header = () => {
    const { credits, maskTokens, isFetching } = useCredits()
    const { reload } = useRouter()
    const { setParam } = useParams()

    const openCredits = () => {
        setParam('credits', 'true')
    }

    return (
        <div className='bg-background border-b top-0 z-20 sticky'>
            {/* <div className='flex w-full p-2 justify-center text-center  items-center bg-secondary gap-2'>
                <div
                    className='cursor-pointer'
                    onClick={() => {
                        event(`ad-card-nude-bot`, {
                            category: 'content',
                            label: 'nude-bot',
                        })
                        window.open(config.clBoturl, '_blank')
                    }}
                >
                    Try our new
                    <SparklesText
                        className='text-sm sm:text-md inline px-2'
                        text='AI Clothes Removal Bot'
                        sparklesCount={5}
                    />
                    for free!
                </div>
            </div> */}
            <div className='flex flex-row w-full justify-between items-center'>
                <div className='flex items-center flex-row'>
                    <div className='flex items-center cursor-pointer' onClick={reload}>
                        <Image
                            className='w-10 m-2 rounded-md object-cover aspect-square'
                            src='/generated/android-chrome-72x72.png'
                            height={50}
                            width={50}
                            alt='logo'
                        />
                        <h2 className='hidden sm:block text-lg font-semibold'>Leakshaven</h2>
                    </div>

                    <NavigationMenu className='ml-2 hidden sm:block'>
                        <NavigationMenuList>
                            <NavigationMenuItem>
                                <NavigationMenuTrigger>ToS & Privacy</NavigationMenuTrigger>
                                <NavigationMenuContent className=''>
                                    <ul className='grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]'>
                                        {navItemsLegal.map((item) => (
                                            <ListItem key={item.url} href={item.url} title={item.title}>
                                                {item.text}
                                            </ListItem>
                                        ))}
                                    </ul>
                                </NavigationMenuContent>
                            </NavigationMenuItem>
                        </NavigationMenuList>
                    </NavigationMenu>

                    <Button
                        variant='outline'
                        className='m-1 hidden sm:flex justify-between items-center'
                        asChild
                    >
                        <Link href='https://theporndude.com' target='_blank'>
                            <Image
                                width='50'
                                height='50'
                                className='h-[1.2rem] w-[1.2rem]'
                                src='images/thepornodude.webp'
                                alt='ThePornDude'
                            />
                            <div className='ml-1 '>ThePornDude</div>
                        </Link>
                    </Button>
                </div>

                <div className='flex items-center flex-row'>
                    <Button
                        variant='outline'
                        onClick={openCredits}
                        className='flex items-center relative border-0 animate-glow justify-center text-amber-300 mr-3'
                    >
                        {!maskTokens ? (
                            <span className='text-md '>{String(credits)}</span>
                        ) : (
                            <Infinity className='h-4 w-4 ' />
                        )}

                        <CircleUserIcon
                            className={clsx('ml-2 h-[1.2rem] w-[1.2rem]', isFetching && 'animate-spin')}
                        />
                    </Button>
                    <ButtonRow className='hidden sm:flex' />
                </div>
            </div>
        </div>
    )
}

export const ButtonRow: React.FC<{ className: string }> = ({ className }) => (
    <>
        {socialLinks.map((link) => (
            <Button
                variant='outline'
                key={link.title}
                className={cn('m-1', className)}
                size='icon'
                asChild
            >
                <Link href={link.url} target='_blank'>
                    {link.icon}
                </Link>
            </Button>
        ))}

        <ModeToggle className={className} />
    </>
)

export const ListItem = React.forwardRef<React.ElementRef<'a'>, React.ComponentPropsWithoutRef<'a'>>(
    ({ className, title, children, ...props }, ref) => {
        return (
            <li>
                <NavigationMenuLink asChild>
                    <a
                        ref={ref}
                        className={cn(
                            'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
                            className
                        )}
                        {...props}
                    >
                        <div className='text-sm font-medium leading-none'>{title}</div>
                        <p className='line-clamp-2 text-sm leading-snug text-muted-foreground'>
                            {children}
                        </p>
                    </a>
                </NavigationMenuLink>
            </li>
        )
    }
)
ListItem.displayName = 'ListItem'

export default Header
