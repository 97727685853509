import { IconBrandDiscord, IconBrandTelegram } from '@tabler/icons-react'
import React from 'react'

import config from '@/config'

export const navItemsLegal = [
    {
        title: 'Terms of Service',
        url: '/tos',
        text: 'Terms of service and use',
    },
    {
        title: 'Privacy Policy',
        url: '/privacy-policy',
        text: 'Privacy policy and data collection',
    },
    {
        title: 'DMCA',
        url: '/dmca',
        text: 'DMCA and content removal',
    },
    {
        title: 'Legal Compliance',
        url: '/18-usc-2257',
        text: '18 USC 2257 and legal compliance',
    },
]

export const socialLinks = [
    {
        title: 'Discord',
        url: config.discordServer,
        icon: <IconBrandDiscord className='h-[1.2rem] w-[1.2rem] text-purple-300' />,
    },
    {
        title: 'Telegram',
        url: config.telegramChannel,
        icon: <IconBrandTelegram className='h-[1.2rem] w-[1.2rem] text-blue-300' />,
    },
]
