import { IconCurrencyBitcoin } from '@tabler/icons-react'
import { clsx } from 'clsx'
import * as React from 'react'
import { Code, Mastercard, Paypal, Visa } from 'react-payment-logos/dist/flat-rounded'

export const PaymentIcon = [<IconCurrencyBitcoin className='h-5 text-orange-500' key='bitcoin' />]

interface PaymentOptionsProps {
    className?: string
}

const PaymentOptions: React.FC<PaymentOptionsProps> = ({ className }) => {
    return (
        <div className={clsx('flex items-center justify-center gap-1 mt-3', className)}>
            <Paypal className='h-6 ' />
            <Visa className='h-6 ' />
            <Mastercard className='h-6  ' />
            <Code className='h-6 ' />
            {PaymentIcon.map((Icon, index) => (
                <div key={index} className='flex items-center bg-muted  p-1 rounded-sm '>
                    {Icon}
                </div>
            ))}
        </div>
    )
}

export default PaymentOptions
