import {
    IconBrandMastercard,
    IconBrandPaypal,
    IconBrandTelegram,
    IconBrandVisa,
    IconCurrencyBitcoin,
    IconCurrencyEthereum,
} from '@tabler/icons-react'
import { BadgeDollarSign, ShoppingCart } from 'lucide-react'
import * as React from 'react'

import { FakeCardProps } from '@/components/Content/FakeCard'
import SparklesText from '@/components/magicui/sparkles-text'
import PaymentOptions from '@/components/User/PaymentOptions'
import config from '@/config'

export const PaymentIcon = [
    <IconBrandPaypal className='h-6 w-6 text-blue-800' key='paypal' />,
    <IconBrandMastercard className='h-6 w-6 text-red-600' key='mastercard' />,
    <IconBrandVisa className='h-6 w-6 text-blue-600' key='visa' />,
    <IconCurrencyBitcoin className='h-6 w-6 text-orange-500' key='bitcoin' />,
    <IconCurrencyEthereum className='h-6 w-6 text-purple-600' key='ethereum' />,
]

export const fakeCardsData: FakeCardProps[] = [
    // {
    //     isAdCard: true,
    //     size: '92 GB',
    //     videos: 80,
    //     title: 'Heaven POV',
    //     text: 'Get the most requested archive ever!',
    //     Icon: <IconBrandTelegram className='' />,
    //     image: 'https://s3.leakshaven.com/static/havenpov.jpg',
    //     link: 'https://t.me/heavenpovv',
    //     campaign: 'heavenpov',
    //     rate: 0.5,
    // },

    // Maria Luna
    // {
    //     isAdCard: true,
    //     size: '4 GB',
    //     videos: 11,
    //     images: 289,
    //     title: 'Maria Luna',
    //     text: '100% Free Trial',
    //     Icon: <IconBrandOnlyfans className='' />,
    //     image: [
    //         'https://v2.pixelvault.co/cm074peg700mo9g5yslgecdnv/direct',
    //         'https://v2.pixelvault.co/cm074pyxl00mq9g5yt2lleo2k/direct',
    //         'https://v2.pixelvault.co/cm074qf2500ms9g5yatdyehx7/direct',
    //     ],
    //     link: 'https://onlyfans.com/action/trial/ssyc4wwpkb2vrhnv4suiuoinieacynsb',
    //     campaign: 'fanscribe',
    //     rate: .3,
    //     borderAnimation: true,
    //     borderColors: '#00AFF0',
    //     callToAction: 'Free Onlyfans',
    //     callToActionStyle: 'bg-[#78b4e8]',
    // },
    // // Mia Shica
    // {
    //     isAdCard: true,
    //     size: '4 GB',
    //     videos: 11,
    //     images: 289,
    //     title: 'Mia Shica',
    //     text: '100% Free Trial',
    //     Icon: <IconBrandOnlyfans className='' />,
    //     image: [
    //         'https://v2.pixelvault.co/cm075epj200no9g5yh02cjdpv/direct',
    //         'https://v2.pixelvault.co/cm075f1ai00nq9g5ylirlo3lo/direct',
    //         'https://v2.pixelvault.co/cm075fbe800ns9g5ys9065syg/direct',
    //         'https://v2.pixelvault.co/cm075fjxn00nu9g5ypldyqkdp/direct',
    //     ],
    //     link: 'https://onlyfans.com/action/trial/m34xud8lrc5dzgnyafzw0hnzqhqjxchr',
    //     campaign: 'fanscribe',
    //     rate: .3,
    //     borderAnimation: true,
    //     borderColors: '#00AFF0',
    //     callToAction: 'Free Onlyfans',
    //     callToActionStyle: 'bg-[#78b4e8]',
    // },
    //
    // // Olivia Wolf
    // {
    //     isAdCard: true,
    //     size: '7 GB',
    //     videos: 11,
    //     images: 421,
    //     title: 'Olivia Wolf',
    //     text: '100% Free Trial',
    //     Icon: <IconBrandOnlyfans className='' />,
    //     image: [
    //         'https://v2.pixelvault.co/cm075horf00nw9g5yol96gdsq/direct',
    //         'https://v2.pixelvault.co/cm075i89e00ny9g5ydmtjjmia/direct',
    //         'https://v2.pixelvault.co/cm075ijrl00o09g5ytdo7zjh2/direct',
    //     ],
    //     link: 'https://onlyfans.com/action/trial/jgfkfhab2c8eubvn9bpc6zof37ayiave',
    //     campaign: 'fanscribe',
    //     rate: .3,
    //     borderAnimation: true,
    //     borderColors: '#00AFF0',
    //     callToAction: 'Free Onlyfans',
    //     callToActionStyle: 'bg-[#78b4e8]',
    // },
    //
    // // Peach
    // {
    //     isAdCard: true,
    //     size: '3 GB',
    //     videos: 4,
    //     images: 59,
    //     title: 'Peach',
    //     text: '3 months free',
    //     Icon: <IconBrandOnlyfans className='' />,
    //     image: [
    //         'https://v2.pixelvault.co/cm075ll9800o29g5y17nb9690/direct',
    //         'https://v2.pixelvault.co/cm075lver00o49g5yqifxustl/direct',
    //         'https://v2.pixelvault.co/cm075m4za00o69g5yolasinwn/direct',
    //     ],
    //     link: 'https://onlyfans.com/action/trial/fecqpnjmfbqyy4kk1arlphjg9m1pvd5u',
    //     campaign: 'fanscribe',
    //     rate: .3,
    //     borderAnimation: true,
    //     borderColors: '#00AFF0',
    //     callToAction: 'Free Onlyfans',
    //     callToActionStyle: 'bg-[#78b4e8]',
    // },
    //
    // // Ashley
    // {
    //     isAdCard: true,
    //     size: '3 GB',
    //     videos: 4,
    //     images: 59,
    //     title: 'Ashley',
    //     text: '3 months free',
    //     Icon: <IconBrandOnlyfans className='' />,
    //     image: [
    //         'https://v2.pixelvault.co/cm075ol7x00o89g5yds258bxp/direct',
    //         'https://v2.pixelvault.co/cm075p3tb00oa9g5y00cgpxbe/direct',
    //         'https://v2.pixelvault.co/cm075pg8700oc9g5yoy9nwbhn/direct'
    //     ],
    //     link: 'https://onlyfans.com/action/trial/w6vw660ipq5j2trerjxmecnqequ7ycmt',
    //     campaign: 'fanscribe',
    //     rate: .3,
    //     borderAnimation: true,
    //     borderColors: '#00AFF0',
    //     callToAction: 'Free Onlyfans',
    //     callToActionStyle: 'bg-[#78b4e8]',
    // },
    // //  Melina Lox
    // {
    //     isAdCard: true,
    //     size: '7 GB',
    //     videos: 42,
    //     images: 417,
    //     title: 'Melina Lox',
    //     text: 'Try out for Free',
    //     Icon: <IconBrandOnlyfans className='' />,
    //     image: [
    //         'https://v2.pixelvault.co/cm074qyy000mu9g5ymf5mjyyw/direct',
    //         'https://v2.pixelvault.co/cm074rib700mw9g5yihv1jl14/direct',
    //         'https://v2.pixelvault.co/cm074rxh700my9g5ykkmg29b8/direct',
    //         'https://v2.pixelvault.co/cm074sd5300n09g5yb4eh2n9q/direct',
    //     ],
    //     link: 'https://onlyfans.com/action/trial/nfn7yreopxrldnlhgmj5exo8z7f7fprs',
    //     campaign: 'fanscribe',
    //     rate: .3,
    //     borderAnimation: true,
    //     borderColors: '#00AFF0',
    //     callToAction: 'Free Onlyfans',
    //     callToActionStyle: 'bg-[#78b4e8]',
    // },
    // // Clara Blanc
    // {
    //     isAdCard: true,
    //     size: '5 GB',
    //     videos: 20,
    //     images: 186,
    //     title: 'Clara Blanc',
    //     text: 'Free Trial',
    //     Icon: <IconBrandOnlyfans className='' />,
    //     image: [
    //         'https://s3.leakshaven.com/static/camp1/clara.webp',
    //         'https://v2.pixelvault.co/cm0bxp774000a10rqqbw9kbrd/direct',
    //         'https://v2.pixelvault.co/cm0bxpkr1000c10rqm256fb5d/direct',
    //         'https://v2.pixelvault.co/cm0bxq2vr000e10rqlzaikeza/direct',
    //         'https://v2.pixelvault.co/cm0bxqoav000g10rq8cwo5tnv/direct'
    //     ],
    //     link: 'https://onlyfans.com/action/trial/k2kjw49z2d3wt9alwdhznvezqmhyjw5b',
    //     campaign: 'fanscribe',
    //     rate: .3,
    //     borderAnimation: true,
    //     borderColors: '#00AFF0',
    //     callToAction: 'Free Onlyfans',
    //     callToActionStyle: 'bg-[#78b4e8]',
    // },
    // // Grace Charis
    // {
    //     isAdCard: true,
    //     size: '8 GB',
    //     videos: 49,
    //     images: 221,
    //     title: 'Grace Charis',
    //     text: 'Free Trial',
    //     Icon: <IconBrandOnlyfans className='' />,
    //     image: [
    //         'https://v2.pixelvault.co/cm0751uci00ng9g5yi4kw31rg/direct',
    //         'https://v2.pixelvault.co/cm075280700ni9g5y138u2s3y/direct',
    //         'https://v2.pixelvault.co/cm07531st00nm9g5y6ku8w8zr/direct',
    //     ],
    //     link: 'https://onlyfans.com/action/trial/v4a3mmu9dl1y0n3ahb3536p9gzaxxeoo',
    //     campaign: 'fanscribe',
    //     rate: .3,
    //     borderAnimation: true,
    //     borderColors: '#00AFF0',
    //     callToAction: 'Free Onlyfans',
    //     callToActionStyle: 'bg-[#78b4e8]',
    // },
    // // Sara Core
    // {
    //     isAdCard: true,
    //     size: '6 GB',
    //     videos: 13,
    //     images: 264,
    //     title: 'Sara Core',
    //     text: '3 months free',
    //     Icon: <IconBrandOnlyfans className='' />,
    //     image: [
    //         'https://v2.pixelvault.co/cm074uvy700n29g5yvt2os5k3/direct',
    //         'https://v2.pixelvault.co/cm074v61100n49g5yv07ah3ty/direct',
    //         'https://v2.pixelvault.co/cm074vk8500n69g5yeaf1rnw6/direct',
    //     ],
    //     link: 'https://onlyfans.com/action/trial/nhn5kqp5rbhyai9njqjhf0x9joi0vcs4',
    //     campaign: 'fanscribe',
    //     rate: .3,
    //     borderAnimation: true,
    //     borderColors: '#00AFF0',
    //     callToAction: 'Free Onlyfans',
    //     callToActionStyle: 'bg-[#78b4e8]',
    // },
    // // Angelina
    // {
    //     isAdCard: true,
    //     size: '3 GB',
    //     videos: 4,
    //     images: 94,
    //     title: 'Angelina',
    //     text: '100% Free Onlyfans',
    //     Icon: <IconBrandOnlyfans className='' />,
    //     image: [
    //         'https://v2.pixelvault.co/cm074cwkr00mg9g5ysq8mg9lo/direct',
    //         'https://v2.pixelvault.co/cm074eaef00mi9g5yv23j66fh/direct',
    //         'https://v2.pixelvault.co/cm074eqld00mk9g5yy3dcywry/direct',
    //         'https://v2.pixelvault.co/cm074f7iu00mm9g5y54mcd4fs/direct',
    //     ],
    //     link: 'https://onlyfans.com/sweetie.angelina',
    //     campaign: 'fanscribe',
    //     rate: .3,
    //     borderAnimation: true,
    //     borderColors: '#00AFF0',
    //     callToAction: 'Free Onlyfans',
    //     callToActionStyle: 'bg-[#78b4e8]',
    // },
    // // Vanessa
    // {
    //     isAdCard: true,
    //     size: '3 GB',
    //     videos: 4,
    //     images: 59,
    //     title: 'Vanessa',
    //     text: '3 months free',
    //     Icon: <IconBrandOnlyfans className='' />,
    //     image: [
    //         'https://v2.pixelvault.co/cm074xlza00n89g5ynacts5qa/direct',
    //         'https://v2.pixelvault.co/cm074xzsa00na9g5yfrzs3ztq/direct',
    //         'https://v2.pixelvault.co/cm074ynjw00nc9g5yz6nwsryk/direct',
    //         'https://v2.pixelvault.co/cm074z2an00ne9g5yofvkllkx/direct',
    //     ],
    //     link: 'https://onlyfans.com/action/trial/wuoubzlzbeyjo29svyoqnyurzorrpbgd',
    //     campaign: 'fanscribe',
    //     rate: .3,
    //     borderAnimation: true,
    //     borderColors: '#00AFF0',
    //     callToAction: 'Free Onlyfans',
    //     callToActionStyle: 'bg-[#78b4e8]',
    // },
    {
        isAdCard: true,
        // title: 'AI Clothes Removal Bot',
        // text: `Created By LeaksHaven | Free Generations | No Watermarks`,
        DescriptionComponent: (
            <div className='flex items-center justify-center flex-grow flex-col '>
                <div className='flex flex-row items-end justify-between'>
                    <SparklesText
                        className='text-xl sm:text-2xl flex items-center'
                        text='AI Clothes Removal Bot'
                        sparklesCount={5}
                    />
                </div>
                <div className='text-center text-md flex-grow'>
                    Created By LeaksHaven | Free Generations | No Watermarks
                </div>
            </div>
        ),
        Icon: <IconBrandTelegram className='' />,
        image: ['https://s3.leakshaven.com/static/clothes_removed.png'],
        link: config.clBoturl,
        campaign: 'nude-bot',
        callToAction: 'Send An Image Now for Free',
        callToActionStyle: 'bg-primary transition-all animate-bounce',
        borderAnimation: 'snake',
        rate: 1,
    },
    {
        isAdCard: true,
        borderAnimation: 'snake',
        Icon: <ShoppingCart className='' />,
        DescriptionComponent: (
            <div className=' flex items-center justify-center flex-grow flex-col '>
                <div className='text-muted-foreground flex flex-row items-end justify-between'>
                    <span className='text-4xl font-bold flex items-center text-amber-300'>
                        50
                        <BadgeDollarSign className='ml-1 w-8 h-8 mt-2' />
                    </span>
                    <div> / day</div>
                    {/*<span className='text-2xl font-bold'>4.99$</span> / month*/}
                    {/*<ArrowRight className='text-white text-xs bg-primary primary-shadow rounded-full mr-2 p-1 ml-2' />*/}
                    {/*<span className='text-2xl font-bold flex items-center'>*/}
                    {/*    50*/}
                    {/*    <BadgeDollarSign className='ml-1 w-7 h-7 mt-2' />*/}
                    {/*</span>*/}
                    {/*<div> / day</div>*/}
                </div>
                <div className=' text-muted-foreground text-xl mt-2'>
                    Premium from <b>$7.99</b>
                </div>
                <PaymentOptions />
            </div>
        ),
        image: ['https://s3.leakshaven.com/static/logo-smoke2.webp'],
        backDropImage: 'https://s3.leakshaven.com/static/animated-logo-still.jpg',
        link: '/plans',
        campaign: 'premium',
        callToAction: 'Get Premium',
        callToActionStyle: 'bg-primary transition-all animate-bounce',
        rate: 1,
    },
]
