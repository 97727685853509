import React, { ReactNode } from 'react'

import Header from '@/components/AppShell/Header'

interface AppShellProps {
    children: ReactNode // This prop allows you to insert content into the main section
}

const AppShell: React.FC<AppShellProps> = ({ children }) => {
    return (
        <div className='flex flex-col h-screen'>
            <Header />
            {children}
        </div>
    )
}

export default AppShell
