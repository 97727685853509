import { useRouter } from 'next/router'
import type { ParsedUrlQuery } from 'querystring'
import { createContext, PropsWithChildren, useContext } from 'react'

interface TQueryContext {
    setParam: (key: string, value: string | undefined) => void
    getParam: (key: string) => string | undefined
    query: ParsedUrlQuery
    isReady: boolean
}

const initialQueryContext: TQueryContext = {} as TQueryContext

const QueryContext = createContext<TQueryContext>(initialQueryContext)

export const QueryProvider = ({ children }: PropsWithChildren<{}>) => {
    const { query, isReady, basePath, push } = useRouter()

    const setParam = (key: string, value: string | undefined) => {
        // keep existing query params
        const newQuery = { ...query }
        // set new value
        if (value) {
            newQuery[key] = value
        } else {
            delete newQuery[key]
        }
        const newUrl = { pathname: basePath, query: newQuery }
        push(newUrl, undefined, { shallow: true })
    }

    const getParam = (key: string) => {
        return query[key] as string | undefined
    }

    return (
        <QueryContext.Provider value={{ setParam, getParam, isReady, query }}>
            {children}
        </QueryContext.Provider>
    )
}

export const useParams = () => {
    const context = useContext(QueryContext)
    if (!context) {
        throw new Error('useQuery must be used within a QueryProvider')
    }
    return context
}

export default useParams
