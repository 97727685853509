import { DateTime, DurationLike } from 'luxon'

export interface IisOlderThanProps {
    key: string
    time: DurationLike
}

export function timedKeyIsOlderThan({ key, time }: IisOlderThanProps) {
    const storedTime = localStorage.getItem(key)
    if (storedTime) {
        const storedTimeAsDateTime = DateTime.fromISO(storedTime)
        const compareDate = DateTime.now().minus(time)
        return compareDate.diff(storedTimeAsDateTime).milliseconds > 0
    }
    return true
}

export const setTimedKey = (key: string) => localStorage.setItem(key, DateTime.now().toISO()!)

// abstracted timed value key
export interface SetIsOlderThanValueKey<T> {
    key: string
    value: T
    overrideTime?: boolean
}

export interface GetIsOlderThanValueKey<T> {
    key: string
    time?: DurationLike
    defaultValue: T
}

export function timedValueKeyIsOlderThan<T>({ key, time, defaultValue }: GetIsOlderThanValueKey<T>): T {
    const keyData = localStorage.getItem(key)

    if (keyData) {
        const { value, storedTime } = JSON.parse(keyData) as { value: T; storedTime: string }
        console.log(value, storedTime)
        if (!time) return value
        const storedTimeAsDateTime = DateTime.fromISO(storedTime)
        const compareDate = DateTime.now().minus(time)
        if (compareDate.diff(storedTimeAsDateTime).milliseconds > 0) {
            setTimedValueKey<T>({ key, value: defaultValue, overrideTime: true })
            return defaultValue
        }
        return value
    }

    return defaultValue
}

export const setTimedValueKey = <T>({ key, value, overrideTime }: SetIsOlderThanValueKey<T>) => {
    const keyData = localStorage.getItem(key)
    let time = DateTime.now().toISO()
    if (keyData && !overrideTime) {
        const { storedTime } = JSON.parse(keyData) as { storedTime: string }
        time = DateTime.fromISO(storedTime).toISO()!
    }
    localStorage.setItem(key, JSON.stringify({ value, storedTime: time }))
}

// number timed value key
export interface SetIsOlderThanNumberValueKey {
    key: string
    value: number
}

export interface GetIsOlderThanNumberValueKey {
    key: string
    time?: DurationLike
}

export function timedNumberValueKeyIsOlderThan({ key, time }: GetIsOlderThanNumberValueKey) {
    return timedValueKeyIsOlderThan<number>({ key, time, defaultValue: 0 })
}

export const setTimedNumberValueKey = ({ key, value }: SetIsOlderThanNumberValueKey) => {
    setTimedValueKey<number>({ key, value })
}

export const increaseTimedNumberValueKey = (key: string) => {
    const currentValue = timedNumberValueKeyIsOlderThan({ key }) as number

    setTimedNumberValueKey({ key, value: currentValue + 1 })
}
