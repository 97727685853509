import { useLocalStorage } from '@mantine/hooks'

import { DisplayMode } from '@/components/Content/ContentCard'
import { StorageKeys } from '@/lib/storage.keys'

export const useDisplayMode = () => {
    const [displayMode, setDisplayMode] = useLocalStorage({
        key: StorageKeys.DISPLAY_MODE,
        defaultValue: DisplayMode.CONTAIN,
    })

    return { displayMode, setDisplayMode }
}

export default useDisplayMode
