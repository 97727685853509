import { useQuery } from '@tanstack/react-query'
import { Package, TrendingUp } from 'lucide-react'
import * as React from 'react'
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import { fetchStats } from '@/api/api'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import sizeFormat from '@/lib/sizeFormat'
import { StatsFrame } from '@/types/stats.type'

export const description = 'A stacked area chart'

const chartConfig: ChartConfig = {
    totalImages: {
        label: 'Images',
        color: 'hsl(var(--chart-1))',
    },
    totalVideos: {
        label: 'Videos',
        color: 'hsl(var(--chart-2))',
    },
    totalContent: {
        label: 'Posts',
        color: 'hsl(var(--chart-3))',
    },
} satisfies ChartConfig

export function StatsGrid() {
    const { data } = useQuery({
        queryKey: ['stats', StatsFrame.DAY],
        queryFn: () => fetchStats(StatsFrame.DAY, 7),
    })

    if (!data) return null

    const avgSize = data.reduce((acc, curr) => acc + Number(curr.totalSize), 0) / data.length

    const avgContent = data.reduce((acc, curr) => acc + curr.totalContent, 0) / data.length

    const lastPeriodIncreasePercentage =
        ((data[data.length - 2].totalContent - data[data.length - 3].totalContent) /
            data[data.length - 3].totalContent) *
        100

    return (
        <Card className='flex flex-grow flex-col'>
            <CardHeader>
                <CardTitle>Daily Content</CardTitle>
                <CardDescription> images and videos uploaded daily</CardDescription>
            </CardHeader>
            <CardContent>
                <ChartContainer config={chartConfig}>
                    <AreaChart
                        accessibilityLayer
                        data={data}
                        margin={{
                            left: 2,
                            right: 2,
                        }}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis dataKey='date' tickLine={false} axisLine={false} />
                        <YAxis
                            tickLine={false}
                            axisLine={false}
                            tickMargin={1}
                            tickCount={3}
                            tickFormatter={(value) => {
                                return value / 1000 + 'K'
                            }}
                        />
                        <ChartTooltip content={<ChartTooltipContent indicator='dot' />} />
                        {/*<ChartLegend content={<ChartLegendContent />} />*/}
                        <defs>
                            <linearGradient id='fillTotalImages' x1='0' y1='0' x2='0' y2='1'>
                                <stop
                                    offset='5%'
                                    stopColor='var(--color-totalImages)'
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset='95%'
                                    stopColor='var(--color-totalImages)'
                                    stopOpacity={0.1}
                                />
                            </linearGradient>
                            <linearGradient id='fillTotalVideos' x1='0' y1='0' x2='0' y2='1'>
                                <stop
                                    offset='5%'
                                    stopColor='var(--color-totalVideos)'
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset='95%'
                                    stopColor='var(--color-totalVideos)'
                                    stopOpacity={0.1}
                                />
                            </linearGradient>
                            <linearGradient id='fillTotalVideos' x1='0' y1='0' x2='0' y2='1'>
                                <stop
                                    offset='5%'
                                    stopColor='var(--color-totalContent)'
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset='95%'
                                    stopColor='var(--color-totalContent)'
                                    stopOpacity={0.1}
                                />
                            </linearGradient>
                        </defs>
                        <Area
                            dataKey='totalImages'
                            type='natural'
                            fill='url(#fillTotalImages)'
                            fillOpacity={0.4}
                            stroke='var(--color-totalImages)'
                            stackId='a'
                        />
                        <Area
                            dataKey='totalVideos'
                            type='natural'
                            fill='url(#fillTotalVideos)'
                            fillOpacity={0.4}
                            stroke='var(--color-totalVideos)'
                            stackId='a'
                        />
                    </AreaChart>
                </ChartContainer>
            </CardContent>
            <CardFooter>
                <div className='flex w-full items-start gap-2 text-sm'>
                    <div className='grid gap-2'>
                        <div className='flex items-center gap-2 gap-x-6 font-medium leading-none'>
                            {lastPeriodIncreasePercentage > 0 && (
                                <div className='flex items-center gap-1 font-medium leading-none'>
                                    {lastPeriodIncreasePercentage.toFixed(2)}%
                                    <TrendingUp size={16} />
                                </div>
                            )}
                            <div className='flex items-center gap-1 font-medium leading-none'>
                                <Package size={16} />
                                <div>
                                    {sizeFormat(avgSize)} / {avgContent.toFixed(0)} posts a day
                                </div>
                            </div>
                        </div>

                        <div className='flex items-center gap-2 leading-none text-muted-foreground'>
                            from {data[0].date} to {data[data.length - 1].date}
                        </div>
                    </div>
                </div>
            </CardFooter>
        </Card>
    )
}

export default StatsGrid
