export interface StatsResponse {
    totalSize: string
    totalImages: number
    totalVideos: number
    totalContent: number
}

export enum StatsFrame {
    HOUR = 'hour',
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
}

export interface DateStatsResponse extends StatsResponse {
    date: Date
}
